import React from "react";
import Form from "../components/sections/widgets/Form";
import Layout from "../layout/Layout";

const FormPage = () => {
  return (
    <Layout>
      <Form />
    </Layout>
  );
};

export default FormPage;
